html {
  scroll-behavior: smooth;
}

/* LEGACY STYLES BELOW */

/*
 * Basic styles, good for a large display. Everything fits in
 * one row, no wrapping. All text based cells grow equally.
 */

.float_child {
  float: left;
}

#locaties {
  border-color: black;
  border-style: dashed;
  border-width: 1px;
  margin: 10px;
  padding: 10px;
}

#locaties input {
  margin: 5px;
}

.table-row {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  flex-wrap: no-wrap;
  -webkit-flex-wrap: no-wrap;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 2;
}

.wrapper {
  display: flex;
  flex-direction: row;
}

.fa-button::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.spinner-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: #cccccc;
  width: 150px;
  height: 150px;
  text-align: center;
  /*half the size of width*/
  margin-left: -75px;
  margin-top: -75px;
  z-index: 999;
  border: 1px solid black;
}

#naarBovenBtn {
  position: fixed;
  /* Fixed/sticky position */
  bottom: 70px;
  /* Place the button at the bottom of the page */
  right: 30px;
  /* Place the button 30px from the right */
  z-index: 99;
  /* Make sure it does not overlap */
  border: none;
  /* Remove borders */
  outline: none;
  /* Remove outline */
  background-color: #e85a1e;
  /* Set a background color */
  color: white;
  /* Text color */
  cursor: pointer;
  /* Add a mouse pointer on hover */
  padding: 15px;
  /* Some padding */
  border-radius: 10px;
  /* Rounded corners */
  font-size: 18px;
  /* Increase font size */
}

.huidigeBedeling {
  background-color: #92ff40;
}

.newBedelingDiv,
.errorMsgDiv {
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: #cccccc;
  width: 300px;
  height: 150px;
  padding-top: 20px;
  text-align: center;
  /*half the size of width*/
  margin-left: -150px;
  z-index: 999;
  border: 1px solid black;
}

.saved-popup i {
  font-size: 4rem;
}

.saved-popup,
#delconf {
  position: fixed;
  left: 50%;
  background-color: #cccccc;
  width: 150px;
  /*half the size of width*/
  margin-left: -75px;
  z-index: 999;
  text-align: center;
  border: 1px solid black;
}

.saved-popup {
  top: 50%;
  height: 130px;
  margin-top: -65px;
  padding-top: 15px;
}

#delconf {
  top: 35%;
  width: 500px;
  margin-left: -250px;
  padding: 20px;
}

.bottombutton.cancel {
  float: left;
}

.bottombutton.final {
  position: relative;
  right: 0px;
}

.delconf-inner i {
  font-size: 4rem;
}

.errorMsgDivInner i {
  font-size: 4rem;
}

#addDier {
  position: relative;
  float: left;
  left: 50%;
  width: 150px;
  margin-left: -75px;
}

.saved-popup-inner {
  width: 75px;
  margin: auto;
}

.spinner-popup div {
  position: relative;
  top: 50%;
}

.column {
  flex-grow: 1;
  -webkit-flex-grow: 1;
  flex-shrink: 1;
  -webkit-flex-shrink: 1;
  vertical-align: top;
  text-align: center;
}

.been {
  width: 130px;
}

.toggle-been {
  width: 130px;
}

.toggle-been {
  width: 130px;
}

.city {
  width: 100px;
}

.remarks {
  min-width: 300px;
  max-width: 500px;
}

.address {
  width: 50px;
}

.index {
  width: 25px;
}

.kioskid {
  width: 75px;
}

.name {
  width: 220px;
  text-overflow: ellipsis;
}

.dier {
  width: 110px;
}

.waarborg {
  width: 100px;
}

.dieren {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  flex-grow: 1;
  -webkit-flex-grow: 1;
  flex-shrink: 1;
  -webkit-flex-shrink: 1;
  flex-flow: row wrap;
}

.kiosk {
  width: 110px;
}

.change {
  width: 85px;
}

.status {
  width: 95px;
}

.assigned {
  width: 100px;
}

.prio {
  width: 100px;
}

.watch,
.add-comment {
  width: 18px;
}

.index {
  text-align: center;
}

.name {
  font-weight: bold;
  color: #337ab5;
}

.header .dieren {
  flex-direction: column;
}

.waarborgbedeling {
  text-align: center;
}

.header .name {
  color: #000000;
}

.comment_row {
  padding: 12px;
}

.shop,
.comment,
.address,
.name,
.city,
.amount-expected,
.amount-came,
.percent-came .date,
.animaltype {
  flex-grow: 1;
  -webkit-flex-grow: 1;
  /*overflow: hidden;*/
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 4px;
}

.viewbedeling .name {
  flex-grow: 0;
  -webkit-flex-grow: 0;
}

.animaltype {
  text-align: center;
  width: 100px;
}

.leftcolumn {
  width: 100px;
}

.lid-inactief {
  background: repeating-linear-gradient(
    -45deg,
    #fff,
    #fff 10px,
    #888 10px,
    #888 20px
  );
}

.lid-inactief .exclamation-click {
  color: black !important;
}

.lid-inactief-warning {
  background: repeating-linear-gradient(
    -45deg,
    #fff,
    #fff 10px,
    #f97 10px,
    #f97 20px
  );
}

.lid-inactief,
.lid-inactief input,
.lid-inactief-warning,
.lid-inactief-warning input {
  text-decoration: line-through;
}

.lid-inactief .name,
.lid-inactief-warning .name {
  color: black !important;
}

/* growable wrappers */

.shop-comment,
.shop-comment-kiosk-reporter,
.attributes,
.allfields {
  flex-grow: 1;
  -webkit-flex-grow: 1;
}

.top_title {
  overflow: hidden;
  float: left;
  margin: 20px;
}

.new_ticket,
.topbutton,
.bottombutton {
  padding: 14px 16px;
  margin: 15px;
  text-decoration: none;
  font-size: 17px;
}

.filterbutton {
  padding: 14px 16px;
  margin: 15px;
  text-decoration: none;
  font-size: 17px;
  border: none;
  border-radius: 4px;
}

.filterbutton.active {
  background-color: #4caf50;
  color: white;
}

#Name {
  padding: 10px;
}

.show_info {
  background-color: #4caf50;
  color: #000000;
  text-align: center;
  padding: 14px 16px;
  margin: 15px;
  margin-top: -5px;
  text-decoration: none;
  font-size: 17px;
  display: block;
  float: right;
}

.mytooltip {
  position: relative;
  display: inline-block;
  overflow: visible;
}

.mytooltip .mytooltiptext {
  display: none;
  background-color: #e85a1e;
  color: black;
  text-align: left;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 10;
  bottom: 120%;
  left: -100px;
  padding-left: 10px;
  padding-right: 10px;
}

.mytooltiptext div {
  margin: 1rem;
}

.mytooltip .mytooltiptext::after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 100px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #e85a1e transparent transparent transparent;
}

.showtooltip .mytooltiptext {
  display: block;
  visibility: visible;
}

.commentbedeling {
  min-width: 100px !important;
  float: right;
  font-weight: bolder;
}
.commentbedeling::placeholder {
  color: rgba(0, 0, 0, 0.1);
  font-weight: normal;
}

.rood {
  color: #e85a1e !important;
}

.name a {
  color: inherit;
}

/*
* Media queries: optimize for different screen widths.
*/

/*
* Media breaks.
*/

@media all and (max-width: 1236px) {
  .shop-comment {
    flex-direction: column;
    -webkit-flex-direction: column;
    width: 220px;
  }
  .shop-comment div {
    flex-grow: 0;
    -webkit-flex-grow: 0;
  }
  .shop-comment > div {
    width: 100%;
  }
  .kiosk-reporter,
  .status-assigned {
    flex-direction: column;
    -webkit-flex-direction: column;
  }
  .kiosk-reporter div,
  .status-assigned div {
    flex-grow: 0;
    -webkit-flex-grow: 0;
  }
}

@media all and (max-width: 956px) {
  .kiosk-reporter {
    flex-direction: row;
    -webkit-flex-direction: row;
  }
  .shop-comment-kiosk-reporter {
    flex-direction: column;
    -webkit-flex-direction: column;
    width: 220px;
  }
  .shop-comment-kiosk-reporter div {
    flex-grow: 0;
    -webkit-flex-grow: 0;
  }
  .shop-comment-kiosk-reporter > div {
    width: 100%;
  }
  .status-assigned-prio {
    flex-direction: column;
    -webkit-flex-direction: column;
  }
  .status-assigned-prio div {
    flex-grow: 0;
    -webkit-flex-grow: 0;
  }
  .icons {
    flex-direction: column;
    -webkit-flex-direction: column;
  }
  .icons div {
    flex-grow: 0;
    -webkit-flex-grow: 0;
  }
  .dates {
    flex-direction: column;
    -webkit-flex-direction: column;
  }
  .dates div {
    flex-grow: 0;
    -webkit-flex-grow: 0;
  }
}

/*
* General good-look styles
*/

.table-row {
  border-bottom: 2px solid #666666;
  border-collapse: collapse;
  padding-bottom: 4px;
  padding-top: 4px;
}

.table-row.header {
  background-color: #ffeedb;
  font-weight: bold;
  padding-top: 6px;
  padding-bottom: 6px;
}

.glyphicon {
  color: #dddddd;
}

.header .glyphicon {
  color: #333;
}

.glyphicon-eye-open.active {
  color: blue;
}

.glyphicon-comment.active {
  color: #3ebb0d;
}

.glyphicon:hover {
  color: #666;
  cursor: pointer;
  text-decoration: underline;
}

.reporter {
  color: #999999;
}

.comment {
  font-style: italic;
}

.prio.HIGH {
  color: red;
}

.prio.MEDIUM {
  color: blue;
}

.prio.LOW {
  color: green;
}

/* ======================================= */

body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.name-waarborg-dieren-opmerking {
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
}

.name-waarborg {
  grid-template-columns: auto 1fr auto;
}

.actief-been-name {
  display: flex;
  flex-direction: row;
}

.actief-been-name-remarks {
  flex-grow: 1;
  flex-direction: row;
  display: flex;
}

#anderetypes {
  width: 50%;
  float: left;
}

* {
  box-sizing: border-box;
}

label {
  display: inline-block;
}

.dierbox,
#lidbox {
  border: 1px solid black;
  border-radius: 10px;
  margin-top: 15px;
}

.button {
  border: none;
  color: white;
  padding: 12px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
}

input[type='submit'],
.new_ticket,
.show_info,
.geobutton,
.topbutton,
.middlebutton {
  text-align: center;
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
}
.savebutton {
  float: none;
  background-color: #4caf50;
  text-align: center;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.middlebutton {
  float: initial;
}

.deletebutton {
  float: right;
  background-color: red !important;
}

.deletebutton:hover {
  background-color: rgb(212, 0, 0) !important;
}

#errclosebutton {
  position: absolute;
  top: -1px;
  right: -1px;
  cursor: pointer;
  border: 1px solid black;
  line-height: 1em;
  padding: 8px;
}

input[type='submit']:hover,
input[type='button']:hover {
  background-color: #45a049;
}

.container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 0px;
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}

.row {
  margin: 0px;
  position: relative;
}

#lidbox,
#dierenbox {
  margin: 20px;
}

#dierenbox {
  overflow: hidden;
}

/* Clear floats after the columns */

.row:after {
  content: '';
  display: table;
  clear: both;
}

.dierboxtitle,
.lidboxtitle {
  text-align: center;
  font-size: 20px;
}

/* my custom styles */

input.searchable {
  width: 100%;
  height: 50px;
  font-size: 18px;
  padding: 10px;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
  display: block;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
    no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
}

.geo {
  width: 33% !important;
}

#huisdierblank {
  z-index: -1;
  max-height: 0;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}

#dierenbox.open #huisdierblank {
  max-height: 1000px;
}

#map_container {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

#map_container #map_canvas {
  left: 0;
  top: 0;
  height: 90%;
  width: 100%;
  position: absolute;
}

.listcheckbox,
.topcheckbox {
  margin-top: 7px !important;
  margin-right: 5px !important;
}

.exclamation-click {
  margin-left: 10px;
  font-size: large;
  color: red;
}

.bottombutton {
  margin-bottom: 50px !important;
}

.filter {
  display: none;
  width: auto;
  clear: both;
  margin: 20px;
  border: black;
  border-style: dotted;
}

.filter label {
  padding: 0px;
  margin-bottom: unset;
}

.errormsg {
  color: red;
  padding: 10px;
}

#login_banner {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.login-page {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
}

.form {
  position: relative;
  background: #ffffff;
  max-width: 320px;
  margin: 0 auto 100px;
  padding: 15px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.form input {
  font-family: 'Roboto', sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}

.form button {
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #4caf50;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}

.form button:hover,
.form button:active,
.form button:focus {
  background: #43a047;
}

.form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}

.form .message a {
  color: #4caf50;
  text-decoration: none;
}

.form .register-form {
  display: none;
}

.container:before,
.container:after {
  content: '';
  display: block;
  clear: both;
}

.container .info {
  margin: 50px auto;
  text-align: center;
}

.container .info h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 36px;
  font-weight: 300;
  color: #1a1a1a;
}

.container .info span {
  color: #4d4d4d;
  font-size: 12px;
}

.container .info span a {
  color: #000000;
  text-decoration: none;
}

.container .info span .fa {
  color: #e85a1e;
}

body {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}

body {
  font-size: 0.8rem;
  font-family: Lato, Helvetica, Arial, Verdana, sans-serif;
  margin: 0;
}

strong {
  font-weight: 700;
}

.wrapper {
  width: 684px;
}

.frontwrapper {
  max-width: 684px;
  margin: auto;
}

h1 {
  text-align: center;
}

h2,
.top_title {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 1px;
}

.logo {
  height: 40px;
  margin-bottom: 74px;
}

.informations {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 126px;
  margin-top: 18px;
  padding: 20px 30px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 #e3e9f3;
  font-size: 0.7rem;
}

.informations:before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background: #007eff;
}

.environment {
  display: inline-block;
  padding: 0 10px;
  margin-bottom: 36px;
  background: #e6f0fb;
  border: 1px solid #aed4fb;
  border-radius: 2px;
  text-transform: uppercase;
  color: #007eff;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.05rem;
}

.cta {
  display: inline-block;
  height: 30px;
  padding: 0 15px;
  margin-top: 32px;
  border-radius: 2px;
  color: #fff;
  font-weight: 700;
  line-height: 28px;
}

.cta i {
  position: relative;
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  font-size: 1rem;
  margin-right: 20px;
}

.cta i:before {
  position: absolute;
  top: 8px;
}

.cta-primary {
  background: #007eff;
}

.cta-secondary {
  background: #6dbb1a;
}

.text-align-right {
  text-align: right;
}

.visible {
  opacity: 1 !important;
}

.titelDieren {
  font-size: 20px;
  font-weight: bold;
  margin: 20px;
  overflow: hidden;
  clear: both;
}

.top-searchfield {
  padding-left: 15px;
  padding-right: 15px;
}

.glower,
.glowercontainer {
  position: relative;
  display: inline-block;
}

.glower::after,
.glowercontainer::after {
  content: '';
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  /* box-shadow: 0 0 5px 5px blue; */
  /*this is here to test */
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.active.glower::after {
  box-shadow: 0 0 5px 5px green;
  opacity: 1;
}

.error.glower::after {
  box-shadow: 0 0 5px 5px red;
  opacity: 1;
}

.active.glowercontainer::after {
  box-shadow: inset 0 0 5px 5px green;
  opacity: 1;
}

.error.glowercontainer::after {
  box-shadow: inset 0 0 5px 5px red;
  opacity: 1;
}

.roderand {
  outline: 2px solid #e85a1e !important;
}

@media only screen and (max-width: 768px) {
  .informations {
    flex-direction: column;
    height: auto;
  }
  .environment {
    width: 100%;
    text-align: center;
    margin-bottom: 18px;
  }
  .text-align-right {
    margin-top: 18px;
    text-align: center;
  }
  .cta {
    width: 100%;
    text-align: center;
  }

  .waarborgbedeling {
    text-align: center !important;
  }
  /* Clear floats after the columns */
  .row:after {
    content: '';
    display: table;
    clear: both;
  }
}

input:invalid {
  border: 2px solid #e85a1e;
}

textarea:invalid {
  border: 2px solid #e85a1e;
}

input[type='radio']:invalid::after,
input[type='checkbox']:invalid::after {
  display: block;
  width: 100%;
  height: 100%;
  background: transparent;
  content: '';
  /* border: 2px solid #e85a1e !important; */
  border-radius: 100%;
  box-sizing: border-box;
}

.block {
  display: block;
}

#table_container {
  margin-top: 10px;
  margin-bottom: 75px;
  clear: both;
}
.bedelingRubriekTitle {
  background-color: #ffbd66;
  padding: 10px;
  text-align: center;
  font-weight: 900;
  font-size: large;
  margin-top: 50px;
  margin-bottom: 3px;
}
.noContent {
  padding-top: 10px;
  text-align: center;
  font-weight: bolder;
  color: gray;
}

#clearSearch {
  width: 40px;
}
.switch-toggle {
  float: left;
  background: #242729;
  border-radius: 5px;
}
.switch-toggle input {
  position: absolute;
  opacity: 0;
}
.switch-toggle input + label {
  padding: 7px;
  float: left;
  color: #fff;
  cursor: pointer;
}

.switch-toggle input:checked.toggle-no + label {
  background-color: rgb(209, 3, 3);
  border-top: 2px solid rgb(255, 34, 34);
  border-left: 1px solid rgb(255, 34, 34);
}

.switch-toggle input:checked.toggle-yes + label {
  background-color: green;
  border-top: 2px solid rgb(65, 255, 65);
  border-left: 1px solid rgb(65, 255, 65);
}

.switch-toggle input:checked.toggle-unset + label {
  background-color: grey;
  border-top: 2px solid rgb(170, 169, 169);
  border-left: 1px solid rgb(170, 169, 169);
}

.switch-toggle label {
  border-radius: 5px;
  margin: 1px;
  width: 40px;
  text-align: center;
  line-height: normal;
}

@media screen and (max-width: 600px) {
  .top-searchfield {
    padding-left: 0px;
    padding-right: 0px;
  }
  .topnav a:not(:first-child) {
    display: none;
  }
  .topnav .icon {
    float: right;
    display: block;
  }
  .name-waarborg {
    flex-direction: row;
    -webkit-flex-direction: row;
  }
  .name-waarborg-dieren-opmerking {
    flex-direction: column;
    -webkit-flex-direction: column;
  }
  .topnav.responsive {
    position: relative;
  }
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
  .col-25,
  .col-75,
  input[type='submit'],
  .deletebutton {
    width: 100%;
    margin-top: 0;
  }
  .deletebutton {
    position: relative;
    right: 0px;
  }
  .commentfield {
    float: left;
  }
  .container-fluid {
    padding-right: 0px;
    padding-left: 0px;
  }
  .table-row {
    padding-left: 4px;
    padding-right: 4px;
  }
  .commentbedeling {
    float: left;
    width: 100% !important;
  }
  .all-fields {
    flex-direction: column;
    -webkit-flex-direction: column;
  }
  .attributes div,
  .allfields div {
    flex-grow: 0;
    -webkit-flex-grow: 0;
  }
  .actief-been-name > div .allfields > div {
    width: 100%;
  }
  .actief {
    width: 0px;
  }
  .name-waarborg {
    flex-direction: row;
    -webkit-flex-direction: row;
  }
  .name-waarborg-dieren-opmerking,
  .allcolumns {
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
  }
  .table-row {
    border-bottom: 3px solid #666666;
    border-collapse: collapse;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .actief-been-name-remarks {
    display: flex;
    flex-direction: column;
  }
  .actief-been-name {
    display: flex;
    flex-direction: row;
  }
}
